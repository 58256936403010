<template>
  <div class="csv-preview-container">
    <div class="grid-title-row">
      <div v-if="title" class="grid-title">{{ title }}</div>
      <div v-else></div>
      <div
        class="csv-export-icon"
        v-if="grid && csvExportFileName"
        @click="exportErrorsInCsv"
        title="Export as CSV"
      >
        <ukt-inline-svg src="download"></ukt-inline-svg>
      </div>
    </div>
    <div ref="grid" :id="id" :style="css" class="ag-theme-alpine"></div>
  </div>
</template>

<script>
import { Grid } from "ag-grid-community";
import shortid from "shortid";
export default {
  name: "csv-viewer",
  // components: { UktInlineSvg },
  props: {
    title: {
      type: String,
    },
    csvExportFileName: {
      type: String,
      default: null,
    },
    css: {
      type: String,
      default: "height:500px",
    },
  },
  data: function () {
    return { id: `csvAgGrid-${shortid.generate()}`, grid: null };
  },
  mounted() {
    this.id = `csvAgGrid-${shortid.generate()}`;
  },
  methods: {
    /**
     * @param {Array} data grid data
     * @param {any} options ag grid options
     */
    createGrid(data, options) {
      this.removeGrid();
      let gridDiv = this.$refs.grid;
      this.grid = new Grid(gridDiv, {
        columnDefs: data.column,
        rowData: data.rows,
        ...options,
      });
    },
    removeGrid() {
      let grid = this.$refs.grid;
      grid && (grid.innerHTML = "");
    },
    exportErrorsInCsv() {
      if (this.grid) {
        this.grid.gridOptions.api.exportDataAsCsv({
          fileName: `${this.csvExportFileName}_.csv`,
        });
      } else {
        this.$snackbar.global.showInfo("Grid is not loaded");
      }
    },
  },
  destroyed() {
    this.removeGrid();
  },
};
</script>

<style lang="less" scoped>
@import "../../less/theme.less";
@import "../../less/media.less";
@import "../../less/text.less";
@import "../../less/color.less";
@import "../../less/main.less";
@import "../../less/page-header.less";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "~ag-grid-community/dist/styles/ag-grid.css";
.csv-preview-container {
  .grid-title-row {
    width: 100%;
    color: @Mako;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    .grid-title {
      color: @Mako;
      font-weight: 500;
    }
    .csv-export-icon {
      cursor: pointer;
      // :deep svg {
      //     width: 18px;
      //     height: 18px;
      // }
    }
  }

  //   :deep .ag-root-wrapper {
  //     .mirage-scrollbar;
  //   }
  :deep(.ag-body-viewport) {
    .mirage-scrollbar;

    // css hack to select text within ag-frid cell
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
  :deep(.success-row) {
    color: white;
    background-color: #00c853;
  }
  // :deep.error-row {
  //     color: white;
  //     background-color: #fa3f4d;
  // }
  :deep(.warning-row) {
    color: white;
    background-color: #ffbb33;
  }
  :deep(.info-row) {
    color: white;
    background-color: RoyalBlue;
  }
  :deep(.ag-body-horizontal-scroll) {
    .mirage-scrollbar;
  }
}
</style>
