<template>
  <div class="page_body">
    <ext-bridge v-on:hintChanged="hintChanged"></ext-bridge>
    <custom-breadcrumb
      :navigations="navs"
      class="breadcrumbs"
    ></custom-breadcrumb>
    <confirmation-dialog
      :title="confirmationTitle"
      :description="confirmationDesc"
      v-if="showDialog"
      ref="confirmation-dialog-ref"
      @close="closeConfirmationDialog"
      @proceed="proceedUploadStoreMapping"
      :syncMessage="syncMessage"
    >
    </confirmation-dialog>
    <e-funnel-card
      :title="'Locations Funnel'"
      :funnelCards="funnelCards"
      :statsData="statsData"
    />
    <div :class="['table-container']">
      <div class="table-container-top">
        <div>
          <p class="selling-location-txt">Selling Locations</p>
          <p class="selling-location-desc">
            Add location code defined on Myntra PPMP to your corresponding
            selling locations on FP to enable the flow of inventory
          </p>
        </div>
        <div
          :class="['bulk-action-btn', { 'bulk-action-bg': isBulkActionOpen }]"
        >
          <nitrozen-button
            :disabled="
              !(
                isEmpty(lastJobData) ||
                lastJobData.status == 'completed' ||
                lastJobData.status == 'failed' ||
                lastJobData.status == 'cancelled'
              )
            "
            theme="secondary"
            v-flatBtn
            @click="
              () => {
                isBulkActionOpen = !isBulkActionOpen;
              }
            "
            >{{ !isBulkActionOpen ? "Bulk Actions" : "Cancel" }}
          </nitrozen-button>
        </div>
      </div>
      <div v-if="isBulkActionOpen" class="table-container-bulk-action">
        <div class="bulk-action-title">Bulk Location Mapping</div>
        <p class="bulk-action-sub-title">
          Follow these steps to map multiple locations in bulk
        </p>
        <div class="bulk-action-cards">
          <div class="bulk-action-card">
            <p class="bulk-action-card-title">
              1. Use the given<strong> Filter</strong> below and click the
              <strong>Download</strong> button to get a CSV file containing all the
              locations
            </p>
            <nitrozen-dropdown
              label="Status"
              :items="statusList"
              v-model="selectedDownloadStatus"
            >
            </nitrozen-dropdown>
            <nitrozen-button
              theme="secondary"
              v-flatBtn
              @click="onDownloadCsv"
              class="bulk-action-card-btn"
              >Download
            </nitrozen-button>
          </div>
          <div class="bulk-action-card">
            <p class="bulk-action-card-title">
              <strong>2. Open</strong> CSV file and follow these steps
            </p>
            <p class="bulk-action-card-desc">
              1. Add the ID of your Myntra PPMP locations in the
              <b>Myntra Ppmp Location Code</b> column
            </p>
            <p class="bulk-action-card-desc">
              2. In the <strong>Status</strong> column, type 'active' for all the
              locations you wish to make available for selling, otherwise
              'inactive' for making the location dormant
            </p>
          </div>
          <div class="bulk-action-card">
            <p class="bulk-action-card-title">
              <strong>3. Upload</strong> the updated CSV file after filling all the
              required details.
            </p>
            <div
              class="drag-drop-block"
              @drop.prevent.stop="onDrop"
              @dragenter.prevent.stop="highlight = true"
              @dragover.prevent.stop="highlight = true"
              @dragleave.prevent.stop="highlight = false"
            >
              <inline-svg
                :src="'upload_cloud'"
                class="feature-icon"
              ></inline-svg>
              <p class="drag-drop-text">Drag and drop your file here</p>
              <p class="drag-drop-text">OR</p>
            </div>
            <nitrozen-button
              theme="secondary"
              v-strokeBtn
              @click="
                () => {
                  $refs.fileUploader.click();
                }
              "
              class="bulk-update-card-btn"
              >Upload
            </nitrozen-button>
            <input
              ref="fileUploader"
              type="file"
              accept=".csv"
              @change="onFileRead($event.target.files)"
            />
          </div>
        </div>
      </div>
      <strip-comp
        :isLastJobBlockOpen="true"
        :lastJobData="lastJobData"
        @handleCancel="cancelJobs($event)"
      />
      <div class="preview-block" v-show="showCSVPreview">
        <div class="preview-block-top">
          <div class="preview-block-title">Seller Mapping Preview (Top 5)</div>
          <nitrozen-button
            v-strokeBtn
            class="cancel-map-btn"
            theme="secondary"
            @click="cancelUploadStoreMapping"
            >Cancel
          </nitrozen-button>
          <nitrozen-button
            :disabled="csvError"
            v-flatBtn
            class="proceed-map-btn"
            theme="secondary"
            @click="openConfirmationDialogBox"
            >Proceed</nitrozen-button
          >
        </div>
        <div class="preview-strip-error" v-if="csvError">
          <inline-svg
            :src="'warning'"
            class="feature-icon preview-error-icon"
          ></inline-svg>
          <p class="preview-error-txt">
            Failed to process SKU Mapping! Please download the error file to
            check and resolve errors. Total rows with error are
            <strong>{{ csvErrorCount }}</strong
            >.
          </p>
          <nitrozen-button
            v-flatBtn
            class="preview-error-btn"
            theme="secondary"
            @click="downloadErrorReport"
            >Download error report</nitrozen-button
          >
        </div>
        <div
          class="csv-preview-diag"
          ref="csv-preview-diag"
          :title="csvPreviewTitle"
        >
          <csv-previewer ref="csv-preview" css="height:300px;"></csv-previewer>
        </div>
      </div>

      <div class="input-box">
        <div class="search">
          <nitrozen-input
            type="search"
            :placeholder="searchPlaceholderText"
            :showSearchIcon="true"
            v-model="searchLocText"
            @input="debouncedSearch"
            :showSuffix="true"
            :custom="true"
          >
            <nitrozen-dropdown
              class="identifier-suffix-2"
              :items="identifierTypes"
              v-model="identifierType"
              @input="
                () => {
                  resetPagination();
                  fetchLocationMapping();
                }
              "
            />
          </nitrozen-input>
        </div>
        <div>
          <nitrozen-dropdown
            label="Status"
            :items="statusList"
            class="status-filter"
            @change="
              () => {
                resetPagination();
                fetchLocationMapping();
              }
            "
            v-model="selectedStatus"
          >
          </nitrozen-dropdown>
        </div>
      </div>
      <location-table
        :filteredStores="filteredStores"
        :headers="headers"
        :hintSteps="hintSteps"
        :doneToNext="doneToNext"
        :isHintActive="isHintActive"
        :dontShowAgain="dontShowAgain"
        :currentHintStage="currentHintStage"
        @clickToVerify="verifyLocation($event)"
        @onEdit="editLocation($event)"
        @hintInactive="setHintInactive"
        :pagination="pagination"
        @fetchPagination="fetchLocationMapping($event)"
        @handlingPrimaryHint="handlePrimaryEvt($event)"
        @hintFinished="handleHintFinished($event)"
      />
      <adm-no-content
        v-if="!filteredStores.length && !inProgress"
        helperText="No locations found"
        class="no-content"
      >
      </adm-no-content>
    </div>

    <loader
      class="loading"
      v-if="inProgress"
      :helperText="loaderHelperText"
    ></loader>
  </div>
</template>

<script>
import {
  NitrozenButton,
  flatBtn,
  strokeBtn,
  NitrozenDropdown,
  NitrozenInput,
} from "@gofynd/nitrozen-vue";

import {
  ExtensionFunnelCard,
  ExtensionStripComp,
  ExtensionLocTable,
} from "marketplace-utilities/web";
import confirmationDialog from "./confirmation-dialog.vue";
import AdminMarketplacesService from "@/services/main.service";
import InlineSvg from "@/components/common/inline-svg";
import extBridge from "./ext-bridge.vue";
import breadCrumb from "@/components/common/breadcrumbs.vue";
import csvPreviwer from "@/components/common/adm-csv-viewer.vue";
import {
  parseCsvV1,
} from "@/helper/csv-parser.helper.js";
import admNoContent from "@/components/common/adm-no-content.vue";
import loader from "@/components/common/adm-loader";
import root from "window-or-global";
import { getCompany, debounce } from "@/helper/utils.js"; 

import pick from "lodash/pick";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import urlJoin from "url-join";
import moment from "moment";

import Ajv from "ajv";
import { saveAs } from "file-saver"; 
import * as csvParser from "papaparse"; 

const MAX_POLLING_COUNTER = 100;

// This are common header fields and also all marhave different marketplaceId fields
// all extra fields are mentioned in EXTRA_HEADERS_FIELDS map
const HEADERS = [
  {
    title: "Name",
    type: "text",
    key: "name",
  },
  {
    title: "Location Type",
    type: "text",
    key: "store_type",
  },
  {
    title: "Location Code",
    type: "text",
    key: "store_code",
  },
  {
    title: "Location Id",
    type: "text",
    key: "store_id",
  },
  {
    title: "City",
    type: "text",
    key: "city",
    obj: "address", // for nested object
  },
  // marketplace id fields will be replaced here
  {
    title: "Marketplaceplace StoreId",
    type: "text",
    key: "marketplace_store_id",
  },
  {
    title: "Action",
    type: "action",
    tooltip:
      "Selling location Inventory sync can be enabled only if GST is verified",
  },
];
const EXTRA_HEADERS_FIELDS = {
  myntra_ppmp: {
    extraFields: [
      {
        key: "marketplace_store_id",
        type: "text",
        title: "Myntra Ppmp Location Code",
      },
      {
        title: "Quantity Buffer",
        type: "text",
        key: "quantity_buffer",
        keyType: "number",
        tooltip:
          "Quantity will be sent only if it's greater than quantity buffer",
      },
      {
        title: "Status",
        type: "badge",
        key: "enabled",
        tooltip:
          "Selling location Inventory sync can be enabled only if GST is verified",
      },
    ],
    schema: {
      "myntra ppmp Location Code": { type: "string" },
      "Quantity buffer": { type: "string" },
    },
  },
};
const STORE_TYPE = {
  high_street: "High Street",
  mall: "Mall",
  warehouse: "Warehouse",
};
const PAGINATION = {
  limit: 10,
  current: 1,
  total: 0,
};

const FUNNEL_CARDS = [
  {
    title: "Total Locations",
    help: "",
    model: "total",
    type: "box",
  },
  {
    icon: "bottom",
    percentage: "100",
    type: "stats",
  },
  {
    title: "Mapped Locations",
    help: "",
    model: "mapped",
    type: "box",
  },
  {
    icon: "bottom",
    percentage: "100",
    type: "stats",
  },
  {
    title: "Active Locations",
    help: "",
    model: "active",
    type: "box",
  },
];

const HINTS = [{}, {}];

const NAVS = [
  {
    title: "Home",
    link: "",
  },
  { title: "Selling Locations" },
];

export default {
  name: "marketplace-stores",
  components: {
    "nitrozen-button": NitrozenButton,
    "nitrozen-dropdown": NitrozenDropdown,
    "nitrozen-input": NitrozenInput,
    "inline-svg": InlineSvg,
    "csv-previewer": csvPreviwer,
    "adm-no-content": admNoContent,
    loader,
    "ext-bridge": extBridge,
    "custom-breadcrumb": breadCrumb,
    "e-funnel-card": ExtensionFunnelCard,
    "strip-comp": ExtensionStripComp,
    "location-table": ExtensionLocTable,
    "confirmation-dialog": confirmationDialog
  },
  directives: {
    flatBtn,
    strokeBtn,
  },
  props: {},
  data() {
    return {
      uploadedStores: [],
      isBulkActionOpen: false,
      isLastJobBlockOpen: true,
      lastJobData: {},
      showCSVPreview: false,
      csvError: false,
      csvErrorData: {},
      hintSteps: [],
      dontShowAgain: true,
      doneToNext: false,
      csvErrorCount: 0,
      hints: HINTS,
      currentHintStage: 0,
      //TODO:rakesh - remove allStores later, hardcoding for testing.
      allStores: [],
      // need copy of inprocessing stores, as it can be cancelled in between
      tempStores: [],
      headers: [],
      funnelCards: [],
      filteredStores: [],
      marketplace: "myntra_ppmp",
      companyId: "",
      storeType: STORE_TYPE,
      searchText: "",
      searchPlaceholderText: "",
      identifierTypes: [
        {
          text: "Myntra Ppmp Location Code",
          value: "location_code",
        },
        {
          text: "Location code",
          value: "location_id",
        },
        {
          text: "Location Name",
          value: "location_name",
        },
      ],
      identifierType: "location_code",
      statusList: [
        {
          text: "All",
          value: "all",
        },
        {
          text: "Active",
          value: "true",
        },
        {
          text: "Inactive",
          value: "false",
        },
      ],
      formatedLastJobDate: "",
      selectedStoreStatus: "all",
      inProgress: false,
      storeInfo: {},
      csvPreviewTitle: "",
      selectedStores: [],
      pagination: { ...PAGINATION },
      storeStats: {
        acount: 0,
        total: 0,
      },
      cleanState: {},
      inventorySync: true,
      enabledStore: [],
      storeToBeSynced: [],
      isHintActive: false,
      statsData: {
        total: 0,
        mapped: 0,
        active: 0,
      },
      searchLocText: "",
      selectedStatus: "all",
      selectedDownloadStatus: "all",
      navs: [],
      jobId: "",
      showDialog: true,
      confirmationTitle: "Confirmation",
      confirmationDesc: "Marking any location as Active/Inactive will change its sync state with the Marketplace and send full/zero inventory updates, respectively, for all mapped articles.",
      syncMessage:
        "Also, send the inventory of mapped articles on the marketplace.",
    };
  },
  mounted() {
    this.hints = HINTS;
    this.navs = NAVS;
    let isHintsAlreadyShown = localStorage.getItem("loc_hint_status");
    if (!isHintsAlreadyShown) {
      localStorage.removeItem("loc_hint_status");
      this.isHintActive = true;
    }
    this.currentHintStage = Number(
      (this.$route.query && this.$route.query.hint) || 0
    );
    this.pagination.current = 1;
    this.pagination.limit = 10;
    this.searchPlaceholderText = "Search by";
    this.inProgress = true;
    let statsPromise = AdminMarketplacesService.fetchStoreStats();
    let locationDetailPromise = this.fetchLocationMapping();
    let lastJobStatusPromise = this.fetchLastJobStats();
    this.setCurrentHint(this.currentHintStage);
    Promise.all([
      locationDetailPromise,
      statsPromise,
      lastJobStatusPromise,
    ]).then((resp) => {
      this.funnelCards = cloneDeep(FUNNEL_CARDS);
      let storeStats = resp[1].data;
      this.lastJobData = resp[2].data || {};
      if (resp[2].data && resp[2].data.status == "running") {
        this.pollJobStatus("", this.interval, 0);
      }
      this.setStoreStats(storeStats);
      this.headers = this.modifyHeaders(cloneDeep(HEADERS));

      this.inProgress = false;
    });
  },
  computed: {
    formatLastJobDate() {
      const gmtDateTime = moment.utc(
        this.lastJobData.date_time,
        "YYYY-MM-DD HH:mm:ss"
      );
      return gmtDateTime.local().format("MMM Do YYYY, hh:mm:ss A");
    },
  },
  methods: {
    changePlaceholderText() {
      const placeholderName = this.identifierTypes.find(
        (item) => item.value === this.identifierType
      );
      this.searchPlaceholderText = "Search by " + placeholderName.text;
    },
    onDrop(e) {
      this.highlight = false;
      const files = e.dataTransfer.files;
      this.onFileRead(files);
    },
    fetchLastJobStats() {
      return AdminMarketplacesService.fetchStoresLastJobStatus();
    },
    setHintInactive() {
      document.cookie =
        "introjs-dontShowAgain" +
        "=; Path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      this.isHintActive = false;
    },
    setCurrentHint(currentHintStage) {
      if (currentHintStage == 0) {
        this.doneToNext = true;
      }
      this.hintSteps =
        currentHintStage == 0
          ? [
              {
                title: "1. Let’s map your first selling location.",
                element: "#edit",
                intro: "Click this “Edit” icon",
                skipLabel: "x",
                hidePrev: true,
                position: "left",
              },
            ]
          : [
              {
                title: "Great Job! What’s more?",
                element: "#marketplace_store_id",
                intro:
                  "You have mapped this selling location! You can map selling locatons in bulk. Also you can always replay this tutorial using the above context menu.",
                position: "left",
              },
            ];
    },
    fetchLocationMapping() {
      let query = {};
      if (this.searchLocText) {
        query["q"] = this.searchLocText.trim();
      }
      if (this.selectedStatus != "all") {
        query["is_active"] = this.selectedStatus;
      }
      query["identifierType"] = this.identifierType;
      query = {
        ...query,
        limit: this.pagination.limit,
        page: this.pagination.current,
      };
      this.inProgress = true;
      return AdminMarketplacesService.fetchStoreMapping(query)
        .then(({ data }) => {
          this.pagination.current = (data.page && data.page.current) || 1;
          this.pagination.total =
            (data.page && data.page.total_item_count) ||
            (data.page && data.page.item_total) ||
            0;
          let storeData = data.items;
          this.setStoreData(storeData);
        })
        .finally(() => {
          this.inProgress = false;
        });
    },
    setStoreStats(storeStats) {
      this.statsData = cloneDeep(storeStats);
      for (let idx = 0; idx < this.funnelCards.length; idx++) {
        if (this.funnelCards[idx].type == "stats") {
          let previousModel = this.funnelCards[idx - 1].model;
          let nextModel = this.funnelCards[idx + 1].model;
          if (storeStats[previousModel] != 0) {
            this.funnelCards[idx].percentage = (
              ((storeStats[previousModel] - storeStats[nextModel]) * 100) /
              storeStats[previousModel]
            ).toFixed(2);
          } else {
            this.funnelCards[idx].percentage = 0.0;
          }
          if (this.funnelCards[idx].percentage == 0) {
            this.funnelCards[idx].icon = "equal_arrow";
          } else {
            this.funnelCards[idx].icon = "bottom_arrow";
          }
        }
      }
    },
    handleHintFinished() {
      localStorage.setItem("loc_hint_status", "shown");
      this.isHintActive = false;
    },
    hintChanged() {
      this.isHintActive = !this.isHintActive;
      this.currentHintStage = 0;
      let isHintsAlreadyShown = localStorage.getItem("loc_hint_status");
      if (isHintsAlreadyShown) {
        localStorage.removeItem("loc_hint_status");
      } else if (!this.isHintActive) {
        localStorage.setItem("loc_hint_status", "shown");
      }
      document.cookie =
        "introjs-dontShowAgain" +
        "=; Path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      this.setCurrentHint(this.currentHintStage);
    },
    editLocation(store) {
      this.$router.push({
        name: "edit_location",
        params: {
          locationData: store,
        },
      });
    },
    handlePrimaryEvt() {
      this.isHintActive = false;
    },

    storeGstStatus(store) {

      let gstStatus = "unverified"; /* eslint-disable-line no-unused-vars*/
      if (store.documents) {
        store.documents.some((doc) => {
          if (doc.type === "gst" && doc.verified === true) {
            gstStatus = "verified";
            return true;
          }
        });
      } else if (store.stage) {
        gstStatus = store.stage;
      }
      return gstStatus;
    },
    isEmpty(obj) {
      return isEmpty(obj);
    },
    getValue(obj, key) {
      return get(obj, key);
    },
    goToStore(id) {
      this.$router
        .push({
          path: urlJoin(this.$basePath, `/profile/edit-store/${id}`),
        })
        .catch(() => {});
    },
    resetPagination() {
      this.pagination.current = 1;
      this.pagination.limit = 10;
    },
    debouncedSearch: debounce(function () {
      if (this.searchLocText.length === 0) {
        this.clearSearchFilter();
      }
      this.resetPagination();
      this.fetchLocationMapping();
    }, 300),
    clearSearchFilter() {
      this.searchLocText = "";
    },
    setStoreData(data) {
      this.allStores = data;
      this.allStores.map((store) => {
        store.error = "";
      });
      this.transformStores(this.allStores);
      this.filteredStores = this.allStores;
      this.cleanState = this.allStores.map((store) => {
        return pick(store, [
          "store_id",
          "marketplace_store_id",
          "enabled",
          "quantity_buffer",
          "store_code",
          // "processing_sla",
        ]);
      });
      this.$forceUpdate();
    },
    // populate stores with transformation
    transformStores(stores) {
      this.allStores = stores.map((store) => {
        if (typeof enabled === String)
          store.enabled = store.enabled === "true" ? true : false;

        // redundant code
        store.enabled = !!store.enabled;
        store.store_type = this.storeType[store.store_type] || store.store_type;
        store.meta = store.meta || {};
        return store;
      });
    },
    // some header fields will be modified as per marketplace
    modifyHeaders(headers) {
      let mkpStoreIdIndex = headers.findIndex(
        (header) => header.key === "marketplace_store_id"
      );

      // remove placeholder header marketplace_store_id
      headers.splice(mkpStoreIdIndex, 1);
      // put actual marketplace headers from extra headers map
      headers.splice(
        mkpStoreIdIndex,
        0,
        ...EXTRA_HEADERS_FIELDS[this.marketplace].extraFields
      );

      return headers;
    },
    downloadErrorReport() {
      const parsedContent = csvParser.unparse(this.csvErrorData.data);
      const file = new File([parsedContent], "sellers-mapping-error.csv", {
        type: "data:text/csv;charset=utf-8",
      });
      saveAs(file);
      this.csvErrorData = {};
    },
    verifyLocation(storeId) {
      let hostnames = root.location.hostname.split(".");
      let fyndPlatformDomain = `${hostnames[2]}.${hostnames[3]}`;
      window.open(
        `https://platform.${fyndPlatformDomain}/company/${getCompany()}/profile/edit-store/${storeId}`,
        "_blank"
      );
    },

    cancelJobs() {
      console.log("Parent Job");
      AdminMarketplacesService.handleCancelJobs({ jobId: this.jobId })
        .then((resp) => {
          let data = resp.data;
          this.lastJobData = data || {};
          this.$snackbar.global.showInfo(data.message);
          this.inProgress = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    onDownloadCsv() {
      let query = {};

      if (this.selectedDownloadStatus != "all") {
        query["is_active"] = this.selectedDownloadStatus;
      }

      this.inProgress = true;
      AdminMarketplacesService.downloadStoreMapping(query)
        .then(({ data }) => {
          if (data.failure_message) {
            this.inProgress = false;
            return this.$snackbar.global.showError(data.failure_message);
          }
          this.loaderHelperText =
            "Location mapping getting downloaded, please wait...";
          this.jobId = data.task_id;
          this.pollJobStatus(data.task_id, this.interval, 0);
          this.$snackbar.global.showSuccess(
            "Started downloading location mappings."
          );
          this.isBulkActionOpen = false;
          this.showCSVPreview = false;
        })
        .catch((err) => {
          this.inProgress = false;

          this.$snackbar.global.showError(
            (err &&
              err.response &&
              err.response.data &&
              err.response.data.message) ||
              (err && err.message) ||
              "Failed to download location mapping"
          );
        });
      // }
    },
    pollJobStatus(jobId, interval, count) {
      this.inProgress = false;

      if (count === MAX_POLLING_COUNTER) {
        this.inProgress = false;
        this.loaderHelperText = "";
        return;
      }
      if (count === 5) interval = 20000;

      this.downloadJobPollId = setTimeout(() => {
        this.fetchLastJobStats()
          .then(({ data }) => {
            console.log("last job data", data);
            this.lastJobData = data;
            console.log("data status", data.status);
            if (data.status == "running") {
              this.jobId = data.task_id;
              console.log("job still running", data);
              return this.pollJobStatus(jobId, interval, ++count);
            }
            console.log("job finished", data);
            // adding stats api
            return this.fetchLocationMapping();
          })
          .catch((err) => {
            console.log("error is", err);
            this.$snackbar.global.showError("Downloading failed");
            this.inProgress = false;
          });
      }, interval);
    },
    refreshPage() {
      this.$router.go();
    },
    pollUploadJobStatus(jobId, interval, count) {
      this.inProgress = false;

      if (count === MAX_POLLING_COUNTER) {
        this.inProgress = false;
        this.loaderHelperText = "";
        return;
      }
      if (count === 5) interval = 20000;

      this.downloadJobPollId = setTimeout(() => {
        this.fetchLastJobStats()
          .then(({ data }) => {
            console.log(data);
            console.log("last job data", data);
            this.lastJobData = data;
            console.log("data status", data.status);
            if (data.status == "running") {
              this.jobId = data.task_id;
              console.log("job still running", data);
              return this.pollUploadJobStatus(jobId, interval, ++count);
            }

            AdminMarketplacesService.fetchStoreStats()
              .then((resp) => {
                let storeStats = resp.data;
                this.setStoreStats(storeStats);
              })
              .catch((err) => {
                console.log(err);
              });
            return this.fetchLocationMapping();
          })
          .catch((err) => {
            this.csvError = false;
            this.showCSVPreview = false;
            this.$refs["csv-preview"].removeGrid();
            this.inProgress = false;
            let errJson =
              err &&
              err.response &&
              err.response.data &&
              err.response.data.store_wrong_creds;
            if (isEmpty(errJson)) {
              errJson = err && err.store_wrong_creds;
            }

            let storeMappingError = [];

            if (isEmpty(this.uploadedStores)) {
              for (let rowIndex in errJson) {
                let row = parseInt(rowIndex);
                this.allStores[row].error = errJson[row];
              }
              this.$forceUpdate();
              return;
            }

            let storeMappingCSVHeaders = [];
            for (let key in this.uploadedStores[0]) {
              storeMappingCSVHeaders.push(key);
            }

            for (let rowIndex in errJson) {
              let row = parseInt(rowIndex);
              let rowError = storeMappingCSVHeaders.map((head) => {
                return `${this.uploadedStores[row][head]}`;
              });
              rowError = [...rowError, errJson[row]];
              storeMappingError.push(rowError);
            }
            storeMappingCSVHeaders = [...storeMappingCSVHeaders, "error"];
            storeMappingError.unshift(storeMappingCSVHeaders);

            this.uploadedStores = {};
            this.downloadCSVFile(
              storeMappingError.join("\n"),
              "uploadError.csv"
            );
          });
      }, interval);
    },
    downloadCSVFile(csvString, fileName, fileLink = "") {
      if (!isEmpty(csvString) || !isEmpty(fileLink)) {
        let link = document.createElement("a");
        link.href = csvString
          ? "data:attachment/csv," + encodeURI(csvString)
          : fileLink;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    cancelUploadStoreMapping() {
      this.csvError = false;
      this.showCSVPreview = false;
      this.$refs["csv-preview"].removeGrid();
    },
    openConfirmationDialogBox() {
      this.showDialog = true;
      setTimeout(() => {
        this.$refs["confirmation-dialog-ref"].openConfirmationDialog();
      }, 0);
    },
    closeConfirmationDialog() {
      this.showDialog = false;
    },
    proceedUploadStoreMapping(isInventoryOrPriceJobTriggered) {
      this.inProgress = true;
      this.pagination.current = 1;
      this.searchText = undefined;
      this.showDialog = false;
      const storeMapping = this.tempStores
        .map((store) => {
          store.marketplace_store_id =
            store.marketplace_store_id && store.marketplace_store_id.trim();
          return store;
        })
        .filter((store) => {
          return store.marketplace_store_id;
        })
        .map((store) => {
          return pick(store, [
            "store_id",
            "marketplace_store_id",
            "enabled",
            "quantity_buffer",
            "store_code",
            // "processing_sla",
            "name",
            "city",
            "store_type",
          ]);
        });

      storeMapping.forEach((store) => {
        store.enabled = store.enabled === "active" ? true : false;
        store.store_id = store.store_id.toString();
      });

      return AdminMarketplacesService.bulkUploadStoreMapping(storeMapping, isInventoryOrPriceJobTriggered)
        .then(({ data }) => {
          this.pollUploadJobStatus(data.task_id, this.interval, 0);

          this.jobId = data.task_id;
          this.$snackbar.global.showSuccess(
            "Started uploading location mappings."
          );
          this.isBulkActionOpen = false;
          this.showCSVPreview = false;
        })
        .catch(() => {
          this.inProgress = false;
        });
    },
    onFileRead(files) {
      this.csvError = false;
      let headerToCheckEmptyString = ["Location Code", "Location Id"];
      parseCsvV1(files, this.validateCsvData, headerToCheckEmptyString);
    },
    onUploadCsv(csvData) {
      const { valid, csvMapping } = csvData; // eslint-disable-line no-unused-vars
      if (!valid) {
        this.csvPreviewTitle = "Seller Location Errors CSV Preview";
        this.$snackbar.global.showError(
          "Failed to process Seller Location. Please download and correct errors."
        );

        this.csvError = true;
        this.showCSVPreview = true;
        this.csvErrorData = csvMapping;
        this.csvErrorCount = csvMapping.data.filter(
          (row) => !!row.error
        ).length;
        this.$refs["csv-preview"].createGrid(
          {
            column: csvMapping.meta.fields.map((e) => ({
              headerName: e,
              field: e,
              resizable: true,
            })),
            rows: csvMapping.data.slice(0, 5),
          },
          { rowClass: "error-row" }
        );
        return;
      }

      let headerMap = this.headers.reduce((prev, item) => {
        prev[item.title] = item;
        return prev;
      }, {});

      let stores = [];

      csvMapping.data.forEach((csvValues) => {
        let str = {};
        for (let head in headerMap) {
          let headData = csvValues[head];

          str[headerMap[head].key] = headData;
        }
        stores.push(str);
      });

      this.csvPreviewTitle = "Seller Locations Mapping CSV Preview";
      this.showCSVPreview = true;

      this.uploadedStores = csvMapping.data;
      this.$refs["csv-preview"].createGrid({
        column: csvMapping.meta.fields.map((e) => ({
          headerName: e,
          field: e,
          resizable: true,
        })),
        rows: csvMapping.data.slice(0, 5),
      });
      // need copy
      this.tempStores = stores;
    },
    validateCsvData(csvMapping) {
      if (this.$refs && this.$refs.fileUploader)
        this.$refs.fileUploader.value = "";
      if (csvMapping.meta.fields.indexOf("Error") !== -1) {
        this.csvError = true;
        return this.$snackbar.global.showError(
          "Invalid columns, remove error column"
        );
      }
      if (!csvMapping.data || !csvMapping.data.length) {
        this.csvError = true;
        return this.$snackbar.global.showError(
          "CSV is empty. Please add data to csv."
        );
      }
      csvMapping.data.forEach((item) => {
        if (typeof item.Status !== "undefined")
          item.Status =
            item.Status.toLowerCase().replace(/↵|\n|\r|\n\r/g, "") === "active"
              ? "active"
              : "inactive";
      });
      // reset filereader instanse
      this.$refs.fileUploader && (this.$refs.fileUploader.value = "");
      const ajv = new Ajv({
        coerceTypes: true,
        jsonPointers: true,
        allErrors: true,
      });

      const storeMappingSchema = {
        type: "object",
        properties: {
          // marketplace_store_id: { type: 'string' },
          Status: { type: "string" },
          "Location Code": { type: "string" },
          "Location Id": { type: "number" },
        },
        required: ["Location Code", "Location Id"],
      };
      // add extra headers schema
      Object.assign(
        storeMappingSchema.properties,
        cloneDeep(EXTRA_HEADERS_FIELDS[this.marketplace].schema)
      );

      // add extra headers required fields
      EXTRA_HEADERS_FIELDS[this.marketplace].extraFields.forEach((field) => {
        if (!storeMappingSchema.required) {
          storeMappingSchema.required = [];
        }
        storeMappingSchema.required.push(field.title);
      });
      let arraySchema = {
        type: "array",
        items: storeMappingSchema,
      };

      const validate = ajv.compile(arraySchema);
      /**
       * Location id, Location code and Marketplace Location Code check for duplicate
       */

      let uniqueLocIds = [],
        uniqueLocCodes = [],
        uniqueMkpLocCodes = [];

      const errorInEntries = {};

      csvMapping.data.forEach((item, itemIdx) => {
        const locId = item["Location Id"];
        const locCode = item["Location Code"];
        const mkpLocCode = item["Myntra Ppmp Location Code"];
        if (!mkpLocCode) {
          errorInEntries[itemIdx] = {
            isEmpty: true,
          };
          return;
        }

        if (locId && uniqueLocIds.includes(locId)) {
          errorInEntries[itemIdx] = {
            isDuplicateLocID: true,
          };
          return;
        }
        if (locCode && uniqueLocCodes.includes(locCode)) {
          errorInEntries[itemIdx] = {
            isDuplicateLocCode: true,
          };
          return;
        }
        if (uniqueMkpLocCodes.includes(mkpLocCode)) {
          errorInEntries[itemIdx] = {
            isDuplicateMkpLocCode: true,
          };
          return;
        }
        uniqueLocIds.push(locId);
        uniqueLocCodes.push(locCode);
        uniqueMkpLocCodes.push(mkpLocCode);
      });

      const valid = validate(csvMapping.data) && isEmpty(errorInEntries);
      if (!valid) {
        this.tempStores = this.populateCSVValidationErrors(
          validate.errors,
          cloneDeep(csvMapping),
          errorInEntries
        );
        csvMapping = this.tempStores;
      }
      return this.onUploadCsv({
        valid,
        csvMapping,
        errors: ["Failed to parse CSV file"],
      });
    },
    populateCSVValidationErrors(ajvErrors, storeMapping, errorInEntries) {
      const errorMappingStrings = {
        isEmpty: "Myntra Ppmp Location Code is a required field.",
        isDuplicateLocID: "Location Id is duplicated.",
        isDuplicateLocCode: "Location Code is duplicated.",
        isDuplicateMkpLocCode: "Myntra Ppmp Location Code is duplicated.",
      };

      ajvErrors = ajvErrors || [];
      let errorMap = {};
      let typeErrors /*eslint-disable-line no-unused-vars*/ = ajvErrors.forEach(
        (error) => {
          let jsonInfo = error.dataPath.split("/");
          if (error.keyword === "type") {
            const key = jsonInfo.pop();
            const rowIndex = +jsonInfo.pop();
            errorMap[rowIndex] =
              (errorMap[rowIndex] || "") + `${key} field ${error.message}.`;
          } else if (error.keyword === "required") {
            const rowIndex = +jsonInfo.pop();
            errorMap[rowIndex] =
              (errorMap[rowIndex] || "") +
              `${error.params.missingProperty} is missing. `;
          }
        }
      );

      for (let errorInEntryIndex in errorInEntries) {
        let errorType = Object.keys(errorInEntries[errorInEntryIndex])[0];
        let errorValue = errorMappingStrings[errorType];
        errorMap[errorInEntryIndex] = errorValue;
      }

      storeMapping.data.forEach((store, index) => {
        store.error = errorMap[index];
      });
      storeMapping.meta.fields.push("error");
      return storeMapping;
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../node_modules/@gofynd/nitrozen-vue/dist/nitrozen.css";
@import "../less/theme.less";
@import "../less/media.less";
@import "../less/text.less";
@import "../less/color.less";
@import "../less/page-header.less";
@import "../less/variables.less";
@import "../less/main.less";

.breadcrumbs {
  margin: 24px;
  padding-left: 24px;
}

p {
  margin: unset;
}

.funnel-container {
  margin: 24px;
  padding: 24px;
  background: #ffffff;
  border-radius: 12px;
  border: 0.5px solid #e0e0e0;

  .funnel-top-span {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .funnel-head {
      width: 80%;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      /* identical to box height, or 156% */

      /* Text/primary */

      color: #41434c;
    }

    .funnel-sm-text {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      /* identical to box height, or 142% */

      /* Text/secondary */

      color: #666666;
    }

    .funnel-sm-bold-text {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      /* identical to box height */

      text-align: right;

      /* Text/primary */

      color: #41434c;
    }
  }

  .funnel-cards {
    display: flex;
    align-items: center;
    margin: 24px 0px 0px;

    .funnel-card {
      padding: 24px;
      //   opacity: 0.1;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      border-radius: 4px;
      width: 18%;
      margin-right: 24px;

      .funnel-card-title {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 12px;
        /* identical to box height, or 150% */

        /* Text/secondary */

        color: #666666;
      }

      .funnel-card-model {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height, or 120% */

        /* Text/primary */

        color: #41434c;
      }
    }

    .funnel-card-active {
      background-color: #e7eeff;
    }

    .funnel-stats {
      margin-right: 24px;

      .funnel-stat-icon-block {
        display: flex;
        justify-content: space-between;
      }

      .funnel-stat-percentage {
        font-family: Inter, sans-serif;
        margin-left: 12px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        /* identical to box height, or 150% */

        /* Gray 3 */

        color: #828282;
      }
    }
  }
}

.fp-stats {
  margin-top: -50px;
  margin-left: auto;
}

.mkps {
  display: inline-flex;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.number-width {
  // min-width: 140px;
  width: 100%;
}

.cursor-disabled {
  cursor: not-allowed;
}

.hint-block {
  display: flex;
  justify-content: space-around;

  :deep(.nitrozen-tooltip-left) {
    top: -55px;
    right: 30px;
  }
}

.hint-element {
  // margin: 25px 10px;
  background-color: white;
  z-index: 1000;
  padding: 8px;
  position: relative;
  border-radius: 4px;
  // border: 10px solid white;
}

.hint-element-last {
  // margin: 25px 10px;
  background-color: white;
  z-index: 1000;
  padding: 12px;
  position: relative;
  border-radius: 4px;
  // border: 10px solid white;
}

.pagination {
  margin-top: 20px;
}

.header-tooltip {
  margin: 2px 0px 2px 2px;

  :deep(.nitrozen-tooltiptext) {
    left: -50px;
    padding: 10px;
    line-height: 1.6;
    margin-bottom: 5px;
  }

  :deep(.nitrozen-tooltip-top:after) {
    left: 60%;
  }
}

.manual-inv-diag {
  :deep(.nitrozen-dialog-body) {
    overflow: unset !important;
  }
}

.manual-inv-label {
  margin-top: 30px;
}

.trigger-btn {
  margin-right: 20px;
}

.sync-button {
  display: block;
  margin: 24px 0px 20px;
}

.brands-body {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

  .selected-brands {
    border: 2px solid RoyalBlue !important;
  }

  .brands-div {
    margin: 0 24px 24px 0;
    border: 2px solid White;
    cursor: pointer;
    padding: 6px;
    height: 100px;
    width: 90px;
    box-sizing: border-box;

    .brand-img-div {
      background-color: Alabaster2;
      height: 60px;
      width: 70px;
      margin: auto;

      .brand-img {
        width: 100%;
        height: 100%;
      }
    }

    .brand-name {
      text-align: center;
      margin: 10px 0px;
      line-height: 1.2;
      font-size: 11px;
      text-overflow: ellipsis;
      word-break: break-all;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.page-error {
  padding: 1%;
}

.no-content {
  :deep(img) {
    height: 250px;
    width: 300px;
  }
}

.preview-block {
  margin-top: 30px;

  .preview-block-top {
    display: flex;
    justify-content: space-between;

    .preview-block-title {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 21px;
      /* identical to box height, or 140% */

      color: #41434c;
    }

    .cancel-map-btn {
      margin-left: auto;
    }

    .proceed-map-btn {
      margin-left: 24px;
    }
  }

  .csv-preview-diag {
    :deep(.nitrozen-dialog-body) {
      overflow: auto;
      max-height: 600px;
    }
  }
}

.preview-strip-gst-error {
  border: none !important;
  padding: unset !important;
  // margin: 12px u !important;
  background: unset !important;

  .preview-error-gst-txt {
    color: gray !important;
    font-weight: bold !important;

    .preview-error-gst-error-txt {
      margin: 0px;
      color: #2e31be !important;
      cursor: pointer;
    }
  }
}

.preview-strip-error {
  background: #ffe7e7;
  border: 1px solid #eb5757;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  align-items: center;
  margin: 12px 0px;

  .preview-error-ico {
    margin-right: 6px;
  }

  .preview-error-txt {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 21px;
    /* identical to box height, or 162% */

    /* Text/primary */

    color: #41434c;
    margin-right: auto;
    margin-left: 6px;
  }

  .preview-error-btn {
    background: #e8505b;
    border-radius: 4px;
  }
}

.tooltip {
  line-height: 1.6;
  margin-left: 5px;

  :deep(.nitrozen-tooltiptext) {
    padding: 15px;
    text-align: left;
    min-width: 210px;
  }

  :deep(.nitrozen-tooltip-bottom) {
    margin-left: -70px;
  }
}

.edit-icon {
  cursor: pointer;
  float: right;

  :deep(svg) {
    height: 24px;
    width: 24px;
  }
}

.pad-left-top {
  margin-left: 20px;
  margin-top: 20px;
}

.store-stats {
  margin-top: 33px;
  margin-left: 5px;
  max-width: 80px;
  margin-right: 5px;
  min-width: 60px;
}

.store-status-sep {
  margin-top: 30px;
  font-size: 16px;
}

.search {
  margin-right: 15px;
  width: 350px;
}

.input-box {
  display: flex;
  justify-content: space-between;
  margin: 24px 0px;
  background-color: #f8f8f8;
  border-radius: 4px;

  padding: 12px;

  :deep(.nitrozen-dropdown-container) {
    flex-direction: row;
  }

  .status-filter {
    :deep(.nitrozen-dropdown-label) {
      margin: 12px;
    }

    :deep(.nitrozen-select-wrapper) {
      min-width: 200px;
    }

    :deep(.nitrozen-select__trigger) {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 23px;
      color: #41434c;
      opacity: 0.8;
    }
  }

  .btns-filters {
    display: flex;
    flex-wrap: wrap;

    input[type="file"] {
      display: none;
    }
  }
}

.table-container {
  padding: 24px;
  margin: 24px;
  background-color: White;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 12px;

  .selling-location-txt {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin: 0px;
    /* identical to box height, or 140% */

    color: #41434c;
  }

  .selling-location-desc {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    margin: 6px 0px;
    /* identical to box height, or 142% */

    /* Text/secondary */

    color: #666666;
  }

  .table-container-top {
    width: 100%;
    display: flex;

    .bulk-action-btn {
      margin-left: auto;
    }

    .bulk-action-bg {
      background: #e7eeff;
      border-radius: 4px;
      padding: 12px;
    }
  }
}

.table-container-bulk-action {
  background: #e7eeff;
  border-radius: 4px;
  padding: 24px;

  .bulk-action-title {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    /* identical to box height, or 140% */

    color: #41434c;
  }

  .bulk-action-sub-title {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    /* identical to box height, or 142% */

    /* Text/secondary */

    color: #666666;
  }

  .bulk-action-cards {
    display: flex;
    justify-content: space-between;

    .bulk-action-card {
      border: 1px dashed #2e31be;
      border-radius: 4px;
      padding: 24px;
      position: relative;
      flex-basis: 25%;
    }

    .bulk-action-card-title {
      font-family: Inter, sans-serif;
      font-style: normal;
      // font-weight: bold;
      font-size: 13px;
      line-height: 19px;
      margin-bottom: 24px;
      /* or 146% */

      /* Text/primary */

      color: #41434c;
    }

    .bulk-action-card-desc {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 37px;
      margin-top: 24px;
      /* or 308% */

      /* Text/secondary */

      color: #666666;
    }

    .bulk-action-card-btn {
      left: 50%;
      margin-left: none;
      position: absolute;
      transform: translate(-50%);
      margin-top: 20px;
    }

    .drag-drop-block {
      .drag-drop-text {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        /* identical to box height, or 167% */

        text-align: center;

        color: #666666;

        margin: 6px;
      }
    }

    input[type="file"] {
      display: none;
    }

    .bulk-update-card-btn {
      left: 50%;
      margin-left: none;
      position: absolute;
      transform: translate(-50%);
    }
  }
}

.last-job-block {
  margin-top: 12px;

  .last-job-details {
    width: 100%;

    .last-job-title {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 21px;
      /* identical to box height, or 140% */

      color: #41434c;
    }

    .last-job-type {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      margin-top: 10px;
      /* identical to box height, or 142% */

      /* Text/secondary */

      color: #666666;
    }

    .last-job-data {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      /* identical to box height, or 142% */

      /* Text/secondary */

      color: #666666;
    }
  }

  .last-job-counts-block {
    display: flex;
    min-width: 400px;
    justify-content: space-between;

    .last-job-count-stats {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 21px;
      color: #41434c;
    }
  }

  .last-job-status-block {
    display: flex;
    min-width: 300px;
    justify-content: space-between;
    align-items: center;

    .last-job-status-pending-txt {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      margin: 6px 0px;
      color: #666666;
    }
  }

  .badge-right {
    display: unset;
    min-width: unset;
  }
}

.csv-preview-diag {
  // margin: 24px;
  :deep(.ag-root-wrapper) {
    border: none;
  }

  :deep(.ag-header-viewport) {
    // background: #f6f6f6;
    /* colour/border */

    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 4px;
  }

  :deep(.ag-body-horizontal-scroll-viewport) {
    // width: 6px;
    height: 6px !important;
  }
}

.headers {
  display: flex;
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  padding: 0px 12px;
  border-radius: 4px 4px 0px 0px;

  .header {
    flex: 0.2;
    padding: 15px 10px;
    text-overflow: ellipsis;

    margin-right: 10px;
  }

  .location-code {
    min-width: 145px;
  }
}

.row-container {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  border-top: none;

  .row-with-strip {
    margin: 0px 12px;
    border-bottom: 1px solid #e0e0e0;

    .row {
      display: flex;

      .row-value {
        display: flex;
        align-items: center;
        flex: 0.2;
        padding: 15px 10px;
        margin-right: 10px;

        .text-overflow {
          font-family: Inter, sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 17px;
          /* or 142% */

          /* Text/secondary */

          color: #666666;
        }

        .bold-text-overflow {
          font-family: Inter, sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 17px;
          /* identical to box height, or 142% */

          /* Text/primary */

          color: #41434c;
        }

        .hint-block {
          display: flex;
          justify-content: space-around;

          :deep(.nitrozen-tooltip-left) {
            top: -55px;
            right: 30px;
          }
        }

        .hint-block-last {
          display: flex;
          justify-content: space-around;

          :deep(.nitrozen-tooltip-left) {
            top: -110px;
            right: 30px;
          }
        }

        .hint-element {
          background-color: white;
          z-index: 1000;
          padding: 8px;
          position: relative;
          border-radius: 4px;
        }
      }

      .location-code {
        min-width: 145px;
      }

      .input-error {
        display: flex;
        flex-direction: column;
        position: relative;
        top: 10px;
        padding-bottom: 0;
      }

      .handle {
        width: 130px !important;
      }

      .input-mkp-store-id {
        display: flex;
        // flex-direction: column;
      }
    }
  }
}

.regular-xsp {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: #41434c;
}

.darker-sm {
  font-weight: 600;
  font-size: 16px;
}

.main {
  position: relative;
  top: 10px;
}

#op {
  position: relative;
  top: 10px;
}

.page_body {
  margin: 0px;
  padding: 0px;
  height: 100vh;

  .mirage-scrollbar {
    overflow: scroll;
  }
}

.successInJob {
  height: 48px;
  display: flex;
  align-items: center;
  background: #e9f5ee;
  padding: 0 0 0 20px;
  border-radius: 4px;

  .download-lastJob-csv {
    margin-left: 3px;
    margin-right: 12px;
    font-size: 13px;
    color: #2e31be !important;
    cursor: pointer;
  }
}

.errorInJob {
  height: 48px;
  display: flex;
  align-items: center;

  background: #ffe7e7;
  padding: 0 0 0 20px;
  border-radius: 4px;

  .download-failedJob-csv {
    margin-left: 3px;
    margin-right: 12px;
    font-size: 13px;
    // color: #2e31be !important;
    color: rgb(232, 80, 91);

    cursor: pointer;
  }
}

.processingInJob {
  height: 48px;
  display: flex;
  align-items: center;
  // background: lavender;
  background-color: #e7eeff;
  padding: 0 0 0 20px;
  border-radius: 4px;
}

.error-btn {
  margin-left: auto;
  margin-right: 10px;
}

.success-download {
  margin-left: auto;
  margin-right: 10px;
}

.strip-message {
  margin-left: 10px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
  color: #41434c;
}

.running-loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid blue;
  // border-bottom: 4px solid blue;
  width: 15px;
  height: 15px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
